/** @jsx jsx */

import { FC } from 'react'
import { jsx } from 'theme-ui'

import Halo from '../../components/halo'
import Intro from '../../components/intro'
import Layout from '../../components/layout'
import ListWithImages from '../../components/list-with-images'
import SignUpCallToAction from '../../components/sign-up-call-to-action'
import routes from '../../constants/routes'
import { footnote, halo, intro, products } from '../../content/institutional-investors'

const InstitutionalInvestors: FC = () => {
  return (
    <Layout>
      <Halo {...halo} />
      <Intro {...intro} />
      <ListWithImages {...{ products }} containerStyles={{ py: 5, bg: `gray1_black` }} />
      <section sx={{ bg: `gray1_black` }}>{footnote}</section>
      <SignUpCallToAction to={routes.institutionalInvestorsRequestInformation} />
    </Layout>
  )
}

export default InstitutionalInvestors
