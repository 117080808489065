/** @jsx jsx */

import { jsx } from 'theme-ui'

import disclosure from '../assets/features/disclosure.png'
import expandInvestmentOpportunities from '../assets/features/expand-investment-opportunities.png'
import portfolioManagement from '../assets/features/portfolio-management.png'
import routes from '../constants/routes'

export const halo = {
  title: `Institutional investors: Portfolio management & optimization`,
  route: routes.institutionalInvestors,
  description: `CartaX offers portfolio management and optimization for institutional investors. Expand your investment opportunities with data-driven investing.`,
}

export const intro = {
  title: (
    <span>
      Illiquidity is <br sx={{ display: [`none`, `block`] }} />
      the new volatility<sup sx={{ ml: 1, fontSize: 3, color: `gray6_slate3` }}>1</sup>
    </span>
  ),
  description: `Optimize your portfolio with greater access to liquidity and disclosure`,
  secondaryDescription: `Exposure to a broader set of investment opportunities supports portfolio diversification and management goals.`,
}

export const products = [
  {
    id: `expand-investment-opportunities`,
    image: <img src={expandInvestmentOpportunities} alt="" sx={{ maxWidth: `100%`, maxHeight: `336px` }} />,
    title: `Expand investment opportunities`,
    description: `Diversify your portfolio with access to more investment options`,
    secondaryDescription: `As an onboarded CartaX institutional investor, you will have unique exposure to high-growth venture-backed companies.`,
  },
  {
    id: `data-driven-investing`,
    image: <img src={disclosure} alt="" sx={{ maxWidth: `100%`, maxHeight: `336px` }} />,
    title: `Data-driven investing`,
    description: `Make informed investment decisions with standardized disclosure and reporting sourced directly from the issuer`,
    secondaryDescription: `Understand capitalization, financials, corporate governance, and valuation through our proprietary investment platform.`,
  },
  {
    id: `portfolio-management`,
    image: <img src={portfolioManagement} alt="" sx={{ maxWidth: `100%`, maxHeight: `336px` }} />,
    title: `Portfolio management for institutional investors`,
    description: `Optimize portfolio management | Move in and out of positions over months, not a decade`,
    secondaryDescription: `Integrating regular, programmatic liquidity on the trading platform with your ownership data allows for agile investment and streamlined portfolio management.`,
  },
]

export const footnote = (
  <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, pt: 0, px: 4, pb: 5 }}>
    <p sx={{ my: 0, position: `relative`, lineHeight: 1.125, color: `gray5_slate4` }}>
      <small sx={{ fontSize: 0 }}>
        <sup sx={{ fontSize: `10px`, position: `absolute`, left: `-0.65rem` }}>1</sup>Investments in private securities
        are speculative, illiquid, and involve a high degree of risk, including the possible loss of your entire
        investment
      </small>
    </p>
  </div>
)
